import styled from "@emotion/styled"
import React from "react"

import PageContainer from "../components/page-container"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <PageContainer>
    <SEO title="404: Not found" />
    <Container>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
    </Container>
  </PageContainer>
)

export default NotFoundPage

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
`
